<template>
  <v-btn
    class="close-button"
    color="primary"
    @click="$emit('click')"
    fab
    x-small
  >
    <v-icon color="white" size="25">mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {};
</script>

<style></style>
