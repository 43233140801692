<template>
  <svg
    id="Arboard_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90.89 90.89"
  >
    <defs></defs>
    <title>Upload_01</title>
    <g id="Layer_24" data-name="Layer 24">
      <path
        class="cls-1"
        d="M3229,1608.74a45.45,45.45,0,1,1,45.45-45.45A45.5,45.5,0,0,1,3229,1608.74Zm0-83.71a38.27,38.27,0,1,0,38.27,38.26A38.3,38.3,0,0,0,3229,1525Z"
        transform="translate(-3183.56 -1517.85)"
      />
      <path
        class="cls-1"
        d="M3240.15,1549.91l-8.31-10.67a3.58,3.58,0,0,0-5.66,0l-8.31,10.67a3.59,3.59,0,0,0,5.66,4.41l1.89-2.42v33.24a3.59,3.59,0,1,0,7.18,0V1551.9l1.89,2.42a3.59,3.59,0,0,0,5.66-4.41Z"
        transform="translate(-3183.56 -1517.85)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Upload",
};
</script>

<style>
.cls-1 {
  fill: #a8000a;
}
</style>
